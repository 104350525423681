.manage-rules-container {
    margin: 0 auto;
    padding: 2rem 9.5rem 4rem;
    width: 100%;
    min-height: 80vh;
    height: auto;
    position: relative;
    & .manage-rules {
        @include component-box-shadow;
        background-color: $colorBackground;
        width: 100%;
        height: 100%;
        padding: 1rem 2.5rem;
        position: relative;
        @include gradient-bottom;

        & .add-rule {
            @include button-text;
        }
        & .view-rule {
            @include button-draft;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            &.published {
                // background-color: $colorSuccess;
                @include button-published;
            }
        }
        & .tier-rule {
            & .table-cell {
                & .view-rule {
                    background-color: $colorDisabled;
                    cursor: not-allowed;
                }
            }
        }
    }

    & .define-rules-cta-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        & .save-and-continue {
            padding: 0.3rem 1rem;
            &.inactive {
                @include button-disabled;
            }
            &.active {
                @include button-primary;
            }
        }
        & button {
            @include button-disabled;
            padding: 0.3rem 1rem;
        }
    }

    & input {
        @include input-text;
    }
}
