.table-container {
    & .table {
        & .table-head {
            & .table-row {
                border-bottom: 2px solid $colorText;
                & .table-cell {
                    font-weight: bold;
                    font-size: 1.12rem;
                }
            }
        }
        & .table-row {
            & .table-cell {
                max-width: 250px;
                word-break: break-word;
            }
        }
    }
    & .table-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
