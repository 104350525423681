.create-program-container {
    margin: 0 auto;
    padding: 2rem 9.5rem 4rem;
    width: 100%;
    min-height: 80vh;
    height: auto;
    position: relative;

    & .show-wizard-instructions {
        text-align: center;
        cursor: pointer;
        color: $colorAccent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem auto;
        & img {
            margin-right: 0.5rem;
        }
    }
    & .wizard-instructions {
        @include component-box-shadow;
        height: auto;
        background-color: $colorBackground;
        padding: 1rem 1.5rem 2.25rem;
        position: relative;
        border-radius: 0.25rem;
        @include gradient-bottom;

        & .wizard-instruction-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & .wizard-instruction-title {
                display: flex;
                & img {
                    margin-right: 0.5rem;
                }
                & h3 {
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }
        & .wizard-instruction-body {
            padding: 2rem;
            & ol {
                & li {
                    margin-bottom: 2rem;
                    & h5 {
                        font-weight: 400;
                        font-size: 1.125rem;
                        & span {
                            font-weight: 800;
                        }
                    }
                    & p {
                        color: $colorTextDisabled;
                        font-size: 0.8rem;
                    }
                }
            }
            & .btn-start-now {
                @include button-primary;
                padding: 0.3rem 1rem;
                margin: 0 auto;
                display: flex;
            }
        }
    }
    // define tiers(step 1)
    .define-tiers-container {
        width: 100%;
        height: auto;
        text-align: left;
        & .define-tiers {
            @include component-box-shadow;
            background-color: $colorBackground;
            width: 100%;
            height: 100%;
            position: relative;
            @include gradient-bottom;

            & .tier {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 1rem 2.5rem;

                & .tier-input-container {
                    margin: 0 1.5rem 0 2.25rem;
                    width: 70%;
                    & .tier-details {
                        display: flex;
                        margin-bottom: 1.5rem;
                        gap: 1.5rem;
                        & div {
                            flex: 1;
                            & label {
                                display: block;
                                margin-bottom: 0.5rem;
                            }
                            & input {
                                @include input-text;
                                &[type='number'] {
                                    @include input-number;
                                }
                            }
                        }
                    }
                    & .tier-description {
                        & label {
                            display: block;
                            margin-bottom: 0.5rem;
                        }
                        & input {
                            @include input-text;
                        }
                    }
                }
                & .delete-tier {
                    margin: auto 0 1rem;
                }
            }
            & .add-tier {
                @include button-text;
                margin-left: 2rem;
                margin-bottom: 1rem;
            }
        }
        & .define-tiers-cta-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2.5rem;
            & .save-and-continue {
                padding: 0.3rem 1rem;
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-primary;
                }
            }
        }
    }
    // define rules(step 2)
    .define-rules-container {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 0 auto;
        & .define-rules {
            @include component-box-shadow;
            background-color: $colorBackground;
            width: 100%;
            height: 100%;
            padding: 1rem 2.5rem;
            position: relative;
            @include gradient-bottom;
            & .table-row {
                :first-child {
                    text-align: center
                }
            }

            & .add-rule {
                @include button-text;
            }
        }

        & .define-rules-cta-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1.5rem;
            & .go-live {
                padding: 0.3rem 1rem;
                margin-right: 2rem;
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-primary;
                }
            }
            & .save-and-continue {
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-secondary;
                }
            }
            & button {
                @include button-disabled;
                padding: 0.3rem 1rem;
            }
        }

        & input {
            @include input-text;
        }
        & p {
            & span {
                font-weight: bold;
            }
        }
    }
    & .create-rule-container{
        width: 90%;
    }

    // define point source
    & .define-point-source-container {
        width: 100%;
        height: auto;
        text-align: left;
        & .define-point-source {
            @include component-box-shadow;
            background-color: $colorBackground;
            width: 100%;
            height: 100%;
            position: relative;
            @include gradient-bottom;

            & .point-source {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 1rem 2.25rem;
                gap: 1.5rem;
                width: 80%;

                & .source-account-name,
                .allocated-points {
                    flex: 1;
                    & label {
                        display: block;
                        margin-bottom: 0.5rem;
                    }
                    & input {
                        @include input-text;
                        &[type='number'] {
                            @include input-number;
                        }
                    }
                }

                & .delete-tier {
                    margin-top: auto;
                }
            }
            & .add-point-source {
                @include button-text;
                margin-left: 2.5rem;
            }
        }
        & .define-point-cta-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2.5rem;
            & .save-and-continue {
                padding: 0.3rem 1rem;
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-primary;
                }
            }
        }
    }
    // wizard confirmation
    .wizard-confirmation-container {
        width: 50%;
        height: auto;
        text-align: center;
        margin: auto;
        @include component-box-shadow;
        background-color: $colorBackground;

        & .wizard-confirmation {
            padding: 5rem 0;
            & .confirmation-header {
                display: flex;
                align-items: center;
                justify-content: center;
                & img {
                    margin-right: 0.5rem;
                }
                & h1 {
                    color: $colorAccent;
                }
            }
            button {
                padding: 0.3rem 1rem;
                margin-top: 2rem;
                background-color: $colorAccent;
                color: $colorTextInverted;
                border-radius: 1rem;
                border: none;
                outline: 0;
            }
        }
    }
    &:has(.wizard-confirmation-container) {
        background-image: url('../../../resources/images/confirm-wizard-bg.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}
