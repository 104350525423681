.app-header {
    background-color: $colorBackground;
    width: 100%;

    & .app-title {
        text-decoration: none;
        padding: 0 2rem;
        border-left: 2px solid $colorAccent;
        font-weight: 700;
        color: $colorAccent;
        text-wrap: nowrap;
        margin: 0 2rem;
        display: flex;
    }

    & .app-icon {
        color: $colorAccent;
    }
    & .nav-dropdown-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
