.footer {
    width: 100%;
    height: auto;
    padding: 1.5rem;
    background-color: $colorFooter;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .footer-links-container {
        display: flex;
        justify-content: space-around;
        align-items: center;

        & .footer-links {
            color: $colorAccent;
            padding: 0 1rem;
            text-decoration: none;
        }
    }

    & .footer-copyright {
        color: $colorText;
        margin-left: auto;
    }
}
