.manage-tiers-container {
    & .manage-tiers {
        @include component-box-shadow;
        background-color: $colorBackground;
        width: 100%;
        height: 100%;
        padding: 1rem 2.5rem;
        position: relative;
        @include gradient-bottom;

        & .add-tier {
            @include button-text;
        }
        & .view-tier {
            @include button-draft;
            border-radius: 0.25rem;

            &.published {
                @include button-published;
            }
        }
    }
}
.tier-details-dialog {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    & .tier-details {
        @include component-box-shadow;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 2.5rem;
        border-radius: 0.25rem;
        background-color: $colorBackground;
        width: 40%;
        @include gradient-bottom;

        & .tier-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0;
        }
        & .tier-details-form {
            & .tier-info {
                display: flex;
                margin-bottom: 1.5rem;
                gap: 1.5rem;
                & div {
                    flex: 1;
                    & label {
                        display: block;
                        margin-bottom: 0.5rem;
                    }
                    & input {
                        @include input-text;
                        &[type='number'] {
                            @include input-number;
                        }
                    }
                }
            }
            & .tier-description {
                margin: 0 0 1rem;
                & label {
                    display: block;
                    margin-bottom: 0.5rem;
                }
                & input {
                    @include input-text;
                }
            }
        }
        & .tier-detials-cta-container {
            margin: 0 0 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & button {
                @include button-secondary;
                padding: 0.25rem 0.5rem;
                &:first-child {
                    @include button-primary;
                }
                &.disabled {
                    @include button-disabled;
                }
            }
        }
    }
}
