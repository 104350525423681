.help-container {
    margin: 0 auto;
    padding: 2rem 9.5rem 4rem;
    width: 100%;
    min-height: 80vh;
    height: auto;
    position: relative;
    & a{
        color: $colorAccent;
        text-decoration: none;
    }
    & ul {
        margin: 0 0 2rem 1.2rem;
    }
    & .faq-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        & .faq-collapse-all{
            @include button-text;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
