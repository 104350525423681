// box shadow
@mixin component-box-shadow {
    box-shadow: 0px 2px 8px 0px $colorComponentShadow;
}
// gradient bg
@mixin gradient-background{
    background: linear-gradient(90deg, #003da5 2.6%, #46db8c 100%);
}
@mixin gradient-bottom{
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.3rem;
        @include gradient-background;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}

// buttons
@mixin button {
    padding: 0.25rem 0.5rem;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-size: normal;
    font-style: normal;
    text-transform: capitalize;
}
@mixin button-primary {
    @include button;
    background-color: $colorAccent;
    color: $colorTextInverted;
    border-radius: 2rem;
}
@mixin button-secondary {
    @include button;
    background-color: $colorBackground;
    color: $colorAccent;
    border: 1px solid $colorAccent;
    border-radius: 2rem;
}
@mixin button-disabled {
    @include button;
    background-color: $colorBackground;
    color: $colorDisabled;
    border: 1px solid $colorDisabled;
    border-radius: 2rem;
    cursor: not-allowed;
}
@mixin button-text {
    @include button;
    background-color: transparent;
    color: $colorAccent;
}
@mixin button-published {
    @include button-primary;
    background-color: $colorSuccess;
    color: $colorTextInverted;
    border-radius: 0.25rem;

    &::after {
        content: url('../../resources/images/view-published.svg');
        margin-left: 0.25rem;
        font-size: inherit;
        vertical-align: middle;
    }
}
@mixin button-draft {
    @include button-secondary;
    background-color: $colorBackground;
    color: $colorAccent;
    &::after {
        content: url('../../resources/images/view-draft.svg');
        margin-left: 0.25rem;
        font-size: inherit;
        vertical-align: middle;
    }
}

// input fields
@mixin input {
    width: 100%;
    height: 3rem;
    padding: 0.5rem 0.6rem;
    font-size: 1rem;
    border-radius: 0.1rem;
    border: 1px solid $colorInput;
    outline: none;
}
@mixin input-text {
    @include input;
}
@mixin input-number {
    @include input;
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}
