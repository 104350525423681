.login-container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, transparent 100%, lightgray 100%),
        url('../../../resources/images/login-gradient-bg.svg') center/cover
            no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    & .login-component {
        height: auto;
        width: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2.25rem;

        & .login-title {
            font-size: 3rem;
            margin: 3rem auto;
            & span {
                color: $colorAccent;
            }
        }
        & .login-description {
            font-size: 1.5rem;
        }

        & .login-btn {
            @include button-primary;
            padding: 0.5rem 5rem;
            margin: 1rem auto;
            display: flex;
        }
    }
}
