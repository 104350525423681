.homepage-container {
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    min-height: 20rem;

    & .welcome-banner {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 2rem 1.5rem;
        box-shadow: none;
        background-image: url('../../../resources/images/welcome-banner-bg.svg');

        & .banner-heading {
            font-size: 3rem;
            font-weight: 700;
            font-style: normal;
            line-height: normal;
            text-transform: capitalize;
            color: $colorTextInverted;
            margin-bottom: 3rem;
        }

        & .banner-subheading {
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            color: $colorTextInverted;
            margin-bottom: 0.5rem;
        }

        & .banner-text {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 100;
            line-height: normal;
            color: $colorTextInverted;
        }
    }

    & .homepage-create-program-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2.5rem auto;

        & .create-program-btn {
            background-color: $colorAccent;
            padding: 0.3rem 0.5rem;
            border-radius: 0.5rem;
            border: 0;
            outline: 0;
            border-radius: 1rem;
            color: $colorTextInverted;
            margin-left: 0.3rem;
        }
    }

    & .homepage-cards-container {
        margin: 0;
        display: flex;
        flex-direction: row;
        width: 100%;

        & .card {
            border: 1px solid $colorAccent;
            border-radius: 1rem;
            min-height: 15rem;
            height: auto;
            margin-right: 1.5rem;
            width: 32.13%;
            background-color: $colorBackground;
            & .card-external-link {
                display: block;
                text-align: right;
            }
            &.active {
                & .card-header {
                    // border-bottom: 1px solid $colorAccent;
                    padding: 1.5rem;
                }

                & .card-body {
                    padding: 1.5rem;
                    & a {
                        text-decoration: none;
                        color: $colorText;
                    }
                }

                & .card-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                & .gradient-border {
                    border: none;
                    outline: none;
                    height: 4px;
                    width: 100%;
                    @include gradient-background;
                }
            }
            &.inactive {
                & .card-header {
                    // border-bottom: 1px solid $colorAccent;
                    padding: 1.5rem;
                    color: $colorDisabled;
                }

                & .card-body {
                    padding: 1.5rem;
                    color: $colorDisabled;
                    & a {
                        text-decoration: none;
                        color: $colorDisabled;
                    }
                }

                & .card-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                & .gradient-border {
                    border: none;
                    outline: none;
                    height: 4px;
                    width: 100%;
                    background-color: $colorDisabled;
                }
            }
        }
    }
}
