.sidenav {
    background-color: $colorAccent;
    color: $colorTextInverted;

    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow-x: hidden;

    &.closed {
        width: 4rem;
        & a {
            display: none;
        }
        & .nav-external-link-icon {
            display: none;
        }
    }

    &.open {
        width: 17rem;
    }
    & a {
        text-decoration: none;
        color: $colorTextInverted;
    }
    & ul.sub-menu {
        margin-left: 4.5rem;
        & li {
            padding: 0;
        }
    }

    .menu {
        color: $colorTextInverted;

        & .menu-item {
            list-style-type: none;
            text-decoration: none;
            white-space: nowrap;
            overflow-x: hidden;

            & .menu-link {
                color: $colorTextInverted;
                text-decoration: none;
            }

            & .sub-menu {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
