.component-container-root {
    position: relative;
}
.component-container {
    margin: 0 auto;
    padding: 2rem 9.5rem 4rem;
    width: 100%;
    min-height: 80vh;
    height: auto;
    position: relative;
    & .instruction {
        margin-bottom: 1.5rem;
        & a{
            color: $colorAccent;
            text-decoration: none;
            &:hover,&:visited, &:active,&:focus{
                color: $colorAccent;
            }
        }
    }
}
