$colorAppBg: #f4fcff;
$colorBackground: #ffffff;
$colorAccent: #1d5b9f;
$colorDisabled: #c7c4c5;
$colorSuccess: #46db86;
$colorError: #c8102e;
$colorText: #000000;
$colorTextInverted: #ffffff;
$colorTextDisabled: #a7a8a9;
$colorFooter: #f6f6f6;
$colorInput: #6e6e6e;
$colorLink: #003da5;

$colorComponentShadow: rgba(11, 11, 11, 0.3);
