.confirm-dialog-container {
    & .confirm-dialog {
        @include component-box-shadow;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 2.5rem;
        border-radius: 0.25rem;
        background-color: $colorBackground;
        width: 40%;
        @include gradient-bottom;
        
        & .confirm-dialog-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0 1.5rem;
            & h3::before {
                content: url('../../../resources/images/alert.svg');
                margin-right: 0.5rem;
                font-size: inherit;
                vertical-align: middle;
            }
        }
        & .confirm-dialog-content {
            margin: 0 0 1.5rem;
            & .confirm-dialog-message {
                margin: 0 0 1rem;
            }
            & .confirm-dialog-note {
                padding: 0.25rem;
                border-radius: 0.25rem;
                background-color: $colorDisabled;
            }
        }
        & .confirm-dialog-cta-container {
            margin: 0 0 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & button {
                @include button-secondary;
                padding: 0.25rem 0.5rem;
                &:first-child {
                    @include button-primary;
                }
            }
        }
    }
}
